<script lang="ts">
  import { _ } from "svelte-i18n";
  import { createEventDispatcher, onMount } from "svelte";

  export let open: boolean;
  const dispatch = createEventDispatcher();
  let modal: any;

  onMount(() => {
    modal = new window.bootstrap.Modal(document.getElementById("missingSupAlertModal"), {});
    document.getElementById("missingSupAlertModal").addEventListener("hidden.bs.modal", function () {
      open = false;
    });
  });

  $: if (open) modal.show();

  async function handleOk() {
    modal.hide();
    dispatch("confirm");
  }

  async function handleCancel() {
    modal.hide();
  }
</script>

<div class="modal fade" id="missingSupAlertModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="d-flex justify-content-between mb-3">
          <div>{$_("diet.missing_supplements_alert")}</div>
          <button
            hidden
            type="button"
            id="missingSupAlertModalCloseBtn"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>

        <div class="text-end">
          <button class="btn btn-primary btn-lg" on:click={handleOk}>
            {$_("ok")}
          </button>

          <button class="btn btn-outline-danger btn-lg" on:click={handleCancel}>
            {$_("cancel")}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

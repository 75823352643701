<script lang="ts">
  import { _ } from "svelte-i18n";
  import { createEventDispatcher } from "svelte";
  import { NumPipe } from "../../../../../pipes/num";
  import type { Visit } from "../../../../../types/Visit";

  type Marker = { label: string; value: any; unit: string; borderColorClass: string; active: boolean };

  export let visit: Visit;
  const dispatch = createEventDispatcher();

  let mainVisitData: Marker[] = [];
  $: mainVisitData = extractData(visit);

  function extractData(visit: Visit): Marker[] {
    const results = visit ? visit.visit_data.results_data.body_composition : null;
    return (mainVisitData = [
      {
        label: "peso",
        value: visit ? visit.visit_data.form_data.body_composition.misurazioni_base.peso : null,
        unit: "kg",
        borderColorClass: "sifa-orange",
        active: true,
      },
      {
        label: "massa_grassa",
        value: results ? results.valutazione_composizione.massa_grassa.value[1] : null,
        unit: results ? results.valutazione_composizione.massa_grassa.unit[1] : null,
        borderColorClass: "fat-mass",
        active: true,
      },
      {
        label: "massa_magra",
        value: results ? results.valutazione_composizione.massa_magra.value[1] : null,
        unit: results ? results.valutazione_composizione.massa_magra.unit[1] : null,
        borderColorClass: "lean-mass",
        active: true,
      },
      {
        label: "metabolismo_basale",
        value: results ? Math.round(results.valutazione_composizione.metabolismo_basale.value) : null,
        unit: results ? results.valutazione_composizione.metabolismo_basale.unit : null,
        borderColorClass: "met-bas",
        active: true,
      },
    ]);
  }

  function customLegendClick(i: number): void {
    mainVisitData[i].active = !mainVisitData[i].active;
    dispatch("marker-click", { index: i });
  }
</script>

<div class="resume">
  <div class="row justify-content-between">
    {#each mainVisitData as datum, i}
      <div class="resume-col col-6 col-md-3 px-1 pt-1 pt-md-0">
        <div
          class="resume-info {datum.borderColorClass}-border-color"
          class:active={datum.active}
          on:click={() => customLegendClick(i)}
        >
          <p class="description">{$_(`visit.${datum.label}`)}</p>
          <p>
            <span class="value">{datum.value ? new NumPipe().transform(datum.value) : "--"}</span>
            <span class="unit">{datum.value ? datum.unit : ""}</span>
          </p>
        </div>
      </div>
    {/each}
  </div>
</div>

<style lang="scss">
  @import "../../../../../styles/_variables";

  $inactive-color: #bbb;
  .resume-col {
    .resume-info {
      cursor: pointer;
      background-color: #f5f5f5;
      padding: 10px;
      height: 100%;
      border-top: 4px solid;
      border-top-color: $inactive-color !important;
      &:not(.active) {
        color: $inactive-color;
      }

      &.active {
        &.sifa-orange-border-color {
          border-color: $sifaorange !important;
        }
        &.fat-mass-border-color {
          border-color: $fat-mass !important;
        }
        &.lean-mass-border-color {
          border-color: $lean-mass !important;
        }
        &.met-bas-border-color {
          border-color: $cell-mass !important;
        }
      }

      p {
        margin-bottom: 0px;
        &.description {
          text-transform: uppercase;
          font-size: 11px;
          line-height: 12px;
          margin-bottom: 0px;
          font-weight: 700;
          min-height: 24px;
          max-height: 24px;
        }
        span.value {
          font-weight: bold;
          font-size: 25px;
          margin-right: 5px;
        }
      }
    }
  }
</style>

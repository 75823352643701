<script lang="ts">
  export let icon: string
  export let id = ''
  export let size = 3 // 1 to 6, 3 default
  export let classes = ''
  export let disabled = false
  export let tooltip = ''
  export let iconColor = 'primary'

  const cssColor =
    iconColor === 'primary' || iconColor === 'secondary'
      ? `$${iconColor}`
      : iconColor
  const cssDim = `${32 + 4 * (size - 4)}px` // 4 pxs for every size
</script>

<style lang="scss">
  @import '../../styles/_variables.scss';

  .icon-wrapper {
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    & i {
      min-width: var(--dim);
      width: var(--dim);
      height: var(--dim);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.disabled {
      i {
        opacity: 0.5;
      }
    }
  }

  $i: 45;
  @while $i < 360 {
    :global(.rotate-#{$i}) {
      transform: rotate($i * 1deg);
    }
    $i: $i + 45;
  }

  .full {
    border: 1px solid #818990;
  }
</style>

<div
  class="icon-wrapper {classes}"
  class:mini={size === 1}
  class:small={size === 2}
  class:fs-5={size === 4}
  class:fs-4={size === 5}
  class:fs-3={size === 6}
  class:disabled
  style="--dim: {cssDim}; --color: {cssColor};"
  title={tooltip}>
  {#if !disabled}
    <div
      {id}
      on:click
      class="w-100 h-100 d-flex align-items-center justify-content-center text-{iconColor}
      {classes}">
      <i class={icon} />
    </div>
  {:else}
    <div
      {id}
      on:click
      class="w-100 h-100 d-flex align-items-center justify-content-center
      text-muted {classes}">
      <i class={icon} />
    </div>
  {/if}
</div>

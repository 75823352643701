import 'svelte-i18n';
/**
 * Basic HTTP client.
 *
 */
export class Http {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }
    async getJson(resource, queryParams = {}, options = {}) {
        const query = queryParams && Object.keys(queryParams).length ? '?' + this.queryParams(queryParams) : '';
        return await this.__json(await this.raiseHTTPException(fetch, `${this.baseUrl}${resource}${query}`, Object.assign({ method: 'GET' }, options)));
    }
    async downloadGet(resource, queryParams = {}, options = {}) {
        const query = queryParams && Object.keys(queryParams).length ? '?' + this.queryParams(queryParams) : '';
        return await this.__file(await this.raiseHTTPException(fetch, `${this.baseUrl}${resource}${query}`, Object.assign({ method: 'GET' }, options)));
    }
    async postJson(resource, body = {}, options = {}) {
        const postHeaders = { "Content-Type": 'application/json' };
        const newOpts = Object.assign({}, options);
        newOpts['headers'] = options['headers'] ? Object.assign(Object.assign({}, options['headers']), postHeaders) : postHeaders;
        return await this.__json(await this.raiseHTTPException(fetch, `${this.baseUrl}${resource}`, Object.assign({ method: 'POST', body: JSON.stringify(body) }, newOpts)));
    }
    async downloadPost(resource, queryParams = {}, body = {}, options = {}) {
        let filename = body.downloadFilename || null;
        const query = queryParams && Object.keys(queryParams).length ? '?' + this.queryParams(queryParams) : '';
        const postHeaders = { "Content-Type": 'application/json' };
        const newOpts = Object.assign({}, options);
        newOpts['headers'] = options['headers'] ? Object.assign(Object.assign({}, options['headers']), postHeaders) : postHeaders;
        return await this.__file(await this.raiseHTTPException(fetch, `${this.baseUrl}${resource}${query}`, Object.assign({ method: 'POST', body: JSON.stringify(body) }, newOpts)), filename);
    }
    // async put(): Promise<any> { }
    // async delete(): Promise<void> { };
    queryParams(obj) {
        return `${new URLSearchParams(obj).toString()}`;
    }
    async raiseHTTPException(f, ...params) {
        const resp = await f(...params);
        if (!resp.ok) {
            let body;
            try {
                body = await resp.json();
            }
            catch (e) {
                body = null;
            }
            throw { status: resp.status, statusText: resp.statusText, response: body };
        }
        return resp;
    }
    async __json(resp) {
        try {
            return await resp.json();
        }
        catch (e) {
            // sinapto APIs returning 200 and some non-json text on server error
            // cannot parse body as json - body contains a string/html error page
            throw { status: 500, statusText: 'Server Error', message: e.toString() };
        }
    }
    genDownloadLink(url, filename = null) {
        const el = document.createElement('a');
        el.setAttribute('hidden', 'true');
        el.setAttribute('href', url);
        el.setAttribute('target', '_blank');
        if (filename) {
            el.download = filename;
        }
        // check if browser is safari ios
        if (navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)) {
            el.setAttribute('target', '_self');
        }
        el.click();
    }
    async __file(resp, filename = null) {
        try {
            const blob = await resp.blob();
            this.genDownloadLink(URL.createObjectURL(blob), filename);
            // const file = window.URL.createObjectURL(blob);
            // window.location.assign(file);
        }
        catch (e) {
            // sinapto APIs returning 200 and some non-json text on server error
            // cannot parse body as json - body contains a string/html error page
            throw { status: 500, statusText: 'Server Error', message: e.toString() };
        }
    }
}

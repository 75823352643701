<script lang="ts">
  import { onMount, createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  import IconBtn from "../../../../lib/IconBtn.svelte";
  import type { Doctor } from "../../../../../types/Doctor";

  export let doctors: Doctor[];
  export let selected: Doctor = null;

  const dispatch = createEventDispatcher();

  onMount(() => handleSelection(doctors[0]));

  function handleSelection(doctor: Doctor) {
    if (!selected || selected.id !== doctor.id) {
      selected = doctor;
      dispatch("doctor-change", { selected });
    }
  }
</script>

{#if doctors && selected}
  <div class="d-flex align-items-center">
    <span class="me-3">
      {$_("dashboard.doctor")}
      <strong>{selected.name}</strong>
    </span>

    <!-- TODO - implement chooser functionality -->
    {#if doctors.length > 1}
      <div class="dropdown">
        <div id="doctors-chooser-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <IconBtn icon="fas fa-list" iconColor="white" />
        </div>
        <ul class="dropdown-menu" aria-labelledby="doctors-chooser-toggle">
          {#each doctors as doctor}
            <li>
              <button on:click={() => handleSelection(doctor)} class="dropdown-item">
                {doctor.name}
              </button>
            </li>
          {/each}
        </ul>
      </div>
    {/if}
  </div>
{/if}

export class SupplementNote {
    constructor(_note) {
        const note = _note.replaceAll(RegExp(SupplementNote.LINK_REGEX, "g"), "[#LINK#]$1[#LINK#]");
        this.parts = note.split("[#LINK#]").map((txt, i) => ({ type: i % 2 === 0 ? "string" : "link", content: txt }));
    }
}
SupplementNote.LINK_REGEX = "((http|ftp|https):\\/\\/([\\w_-]+(?:(?:\\.[\\w_-]+)+))([\\w.,@?^=%&:\\/~+#-]*[\\w@?^=%&\\/~+#-]))";
export class Diet {
    constructor(obj) {
        [
            "diet_name",
            "is_draft",
            "is_template",
            "tags",
            "energy_kcal",
            "carbohydrates",
            "proteins",
            "lipids",
            "alcohol",
            "total_fiber",
            "foods",
            "medical_note",
            "name",
            "id",
            "created_at",
            "schedules",
        ].forEach((k) => (this[k] = obj[k]));
    }
    scheduleHasSupplements(schedule) {
        return !!schedule.supplements && !!schedule.supplements.meals;
    }
    hasSupplements() {
        return this.schedules.reduce((acc, s) => acc && this.scheduleHasSupplements(s), true);
    }
    /**
     * Avoid supplements duplication
     *
     * Checks if supplement with same id is in array before this position.
     */
    suppIsFirstOccurence(s, index, array) {
        return array.findIndex((ss) => ss.id === s.id) === index;
    }
    extractSupplements(schedule) {
        return !schedule.viewMode || !this.scheduleHasSupplements(schedule)
            ? []
            : schedule.supplements.meals
                .map((supMeal) => {
                if (schedule.supplements.viewMode === "daily") {
                    return supMeal.daily.items;
                }
                else {
                    return supMeal.weekly.days.map((day) => day.items).flat();
                }
            })
                .flat()
                .filter(this.suppIsFirstOccurence);
    }
    getSupplements() {
        return this.schedules
            .map((s) => this.extractSupplements(s))
            .flat()
            .filter(this.suppIsFirstOccurence);
    }
    getNotes() {
        return (this.hasSupplements() ? this.schedules.map((s) => s.supplements.note) : [])
            .filter((note) => !!note)
            .map((n) => new SupplementNote(n));
    }
}

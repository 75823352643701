import { userStore } from "../stores/user";
import { CMSService } from "./_cms";
import { StorageService } from "./_storage";
export class AuthenticationService extends CMSService {
    init() {
        const login = this.storage.get('logged_user');
        if (login)
            userStore.set(login);
    }
    setLogin(info) {
        this.storage.set('PHPSESSID', info.PHPSESSID);
        const userInfo = Object.assign({}, info);
        userInfo.PHPSESSID = undefined;
        this.storage.set('logged_user', userInfo);
        userStore.set(userInfo);
    }
    static cleanLogin() {
        const storage = new StorageService();
        storage.del('PHPSESSID');
        storage.del('logged_user');
        userStore.set(null);
    }
    static async isAuthenticated() {
        // Promise from Store
        return new Promise((resolve) => {
            userStore.subscribe(v => {
                if (!v) {
                    const user = new StorageService().get('logged_user') || null;
                    userStore.set(user);
                    resolve(!!user);
                }
                else {
                    resolve(!!v);
                }
            });
        });
    }
    /**
     * Check with the server that the given token is valid for user registration
     */
    async checkToken(email, token) {
        try {
            const res = await this.postJson('/api/manage-patients/patient/check-token', { email, token });
            return !!res.emailExists && !!res.tokenIsActive;
        }
        catch (e) {
            return true;
        }
    }
    /**
     * Handles password reset and user registration (first login is simply a password reset)
     *
     * Also works as a login.
     */
    async resetPassword(form) {
        try {
            const user = await this.postJson('/api/manage-patients/patient/reset-password', form);
            this.setLogin(user);
            return true;
        }
        catch (e) {
            return false;
        }
    }
    /**
     * Send token request to change password
     */
    async requestPasswordReset(form) {
        try {
            // don't care about response
            await this.postJson('/api/manage-patients/patient/request-password-reset', form);
            return true;
        }
        catch (e) {
            return false;
        }
    }
    async login(form) {
        try {
            const loginInfo = await this.postJson('/api/manage-patients/patient/login', form, {}, true, false);
            this.setLogin(loginInfo);
            return true;
        }
        catch (e) {
            return false;
        }
    }
    static async logout(nav) {
        try {
            if (AuthenticationService.isAuthenticated()) {
                const svc = new CMSService();
                await svc.postJson('/api/manage-patients/patient/logout');
            }
        }
        finally {
            AuthenticationService.cleanLogin();
            nav('/login');
        }
    }
    /**
     * Return wrapped logout function to not overlap logout calls on failing of more APIs at the same time
     *
     * Right now it should only works for calls from the same service.
     * Maybe we need to make the control variable `logginOut` shared by store.
     */
    static getManagedLogout(nav) {
        var logginOut = false;
        return async () => {
            if (!logginOut) {
                logginOut = true;
                await AuthenticationService.logout(nav);
                logginOut = false;
            }
        };
    }
    // store data to be used to re-login after password reset
    prepareAfterResetLogin(authData) {
        this.storage.set("authData", authData);
    }
    // check if a password reset as just been done, and use data to re-login
    async tryAfterResetLogin() {
        const authData = this.storage.get("authData");
        if (authData && authData.email && authData.password) {
            this.storage.del("authData");
            return await this.login(authData);
        }
        return false;
    }
}
export class AuthenticatedAPIService extends CMSService {
    constructor(nav, baseUrl) {
        super(baseUrl);
        this.logout = AuthenticationService.getManagedLogout(nav);
    }
    ;
    async getJson(resource, queryParams, options, handleErr) {
        try {
            return await super.getJson(resource, queryParams, options, handleErr, true);
        }
        catch (e) {
            if (e.status === 401) {
                await this.logout();
            }
            throw e;
        }
    }
    async postJson(resource, queryParams, options, handleErr) {
        try {
            return await super.postJson(resource, queryParams, options, handleErr, true);
        }
        catch (e) {
            if (e.status === 401) {
                await this.logout();
            }
            throw e;
        }
    }
    async download(resource, queryParams, options, handleErr) {
        try {
            return super.download(resource, queryParams, options, handleErr, true);
        }
        catch (e) {
            if (e.status === 401) {
                await this.logout();
            }
            throw e;
        }
    }
}

<script lang="ts">
  export let title: string = null;
  export let colorClass: string = "";
</script>

<div class="card h-100 {colorClass}">
  <div class="card-body">
    {#if title}
      <h2 class="mb-3">{title}</h2>
    {/if}
    <slot />
  </div>
</div>

<style lang="scss">
  @import "../../../../styles/_variables";

  .card {
    &.primary {
      background-color: $primary;
      color: white;
    }
  }
</style>

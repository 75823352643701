<script lang="ts">
  import { onMount } from "svelte";
  import { PatientService } from "../../../../services/patient";
  import { navigate } from "svelte-navigator";
  import Loader from "../../../lib/Loader.svelte";
  import DietsBox from "./diets-box/DietsBox.svelte";
  import MainChartBox from "./main-chart-box/MainChartBox.svelte";
  import PatientBox from "./patient-box/PatientBox.svelte";
  import SupplementsBox from "./supplements-box/SupplementsBox.svelte";
  import { Diet, DietsByDoctor } from "../../../../types/Diet";
  import type { Doctor } from "../../../../types/Doctor";
  import type { Visit, VisitsByDoctor } from "../../../../types/Visit";
  import type { Patient } from "../../../../types/Patient";

  const svc = new PatientService(navigate);
  let loading = true;
  let doctorsReady = false;
  let patient: Patient;
  let visitsByDoctor: VisitsByDoctor[];
  let dietsByDoctor: DietsByDoctor[];

  let doctors: Doctor[];
  let selectedDoctor: Doctor;
  let visits: Visit[];
  let diets: Diet[];
  let selectedDiet: Diet;

  onMount(async () => {
    [patient, visitsByDoctor, dietsByDoctor] = await Promise.all([svc.getPatient(), svc.getVisits(), svc.getDiets()]);

    // just check to be sure
    visitsByDoctor = Array.isArray(visitsByDoctor) ? visitsByDoctor : [];
    dietsByDoctor = Array.isArray(dietsByDoctor) ? dietsByDoctor : [];

    dietsByDoctor.forEach((item) => {
      item.diets.sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
    });

    const doctorsDiets: Doctor[] = dietsByDoctor.map((el) => el.doctor);
    const doctorsVisits: Doctor[] = visitsByDoctor.map((el) => el.doctor);
    doctors = doctorsDiets.concat(doctorsVisits.filter((el) => doctorsDiets.findIndex((d) => d.id === el.id) === -1));
    doctorsReady = true;
  });

  function init() {
    loading = true;
    if (selectedDoctor) {
      diets = (dietsByDoctor.find((el) => el.doctor.id === selectedDoctor.id)?.diets || []).map((d) => new Diet(d));
      visits = visitsByDoctor.find((el) => el.doctor.id === selectedDoctor.id)?.visits || [];
    }
    loading = false;
  }

  function handleDoctorSelection(_e: CustomEvent<{ selected: Doctor }>) {
    init();
  }
</script>

<div class="container py-4 loader-wrap">
  {#if loading}
    <Loader />
  {/if}

  <div class="row">
    <div class="col-12 col-xl-4 mb-3 d-flex flex-column">
      <div class="logo-sifa card mb-3 mb-xl-4">
        <div class="card-body">
          <img src="./assets/images/sifa-logo.svg" alt="Sifa app" />
        </div>
      </div>
      <PatientBox
        on:doctor-change={handleDoctorSelection}
        loading={!doctorsReady}
        {patient}
        {doctors}
        bind:selectedDoctor
      />
    </div>
    <div class="col-12 col-xl-8 mb-3">
      <MainChartBox {loading} {visits} {diets} />
    </div>
    <div class="col-12 col-xl-4 mb-3">
      <DietsBox {loading} {diets} bind:selectedDiet />
    </div>
    <div class="col-12 col-xl-8 mb-3">
      <SupplementsBox {selectedDiet} />
    </div>
  </div>
</div>

<script lang="ts">
  import { onMount } from "svelte";
  import { navigate } from "svelte-navigator";
  import { _ } from "svelte-i18n";
  import { AuthenticationService } from "../../services/auth";
  import { debounce } from "../../utils/debounce";
  import { checkRequiredFields, requireFields } from "../../utils/form-utils";
  import EntryScreen from "../lib/EntryScreen.svelte";
  import LoadingButton from "../lib/LoadingButton.svelte";
  import Input from "../lib/Input.svelte";
  import type { Form, LoginForm } from "../../types/Form";

  const authSvc = new AuthenticationService();
  const form: Form<LoginForm> = {
    value: {
      email: null,
      password: null,
    },
    submitted: false,
    valid: false,
    errors: {},
  };

  let loading = false;

  onMount(async () => {
    loading = true;
    if (await AuthenticationService.isAuthenticated()) go();
    if (await authSvc.tryAfterResetLogin()) go();
    loading = false;
  });

  async function handleSubmit() {
    loading = true;
    validate();
    form.submitted = true;
    if (form.valid) {
      const succ = await authSvc.login(form.value);
      if (succ) {
        setTimeout(() => go(), 200);
      }
    }
    loading = false;
  }

  const go = () => {
    loading = false;
    navigate("/dashboard");
  };

  const validate: () => void = debounce(250, () => {
    form.valid = checkRequiredFields(form.value);
    form.errors = requireFields(form.value);
  });
</script>

<EntryScreen>
  <div class="card">
    <div class="card-body p-4">
      <h1 hidden>Login</h1>
      <form class:submitted={form.submitted}>
        <div class="mb-3">
          <label for="login-email">{$_("login.username")}</label>
          <Input
            bind:value={form.value.email}
            on:input={validate}
            errors={form.errors["email"]}
            type="email"
            id="login-email"
          />
        </div>

        <div class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <label for="login-password">{$_("login.password")}</label>
            <a class="small" href="/forgot-password">{$_("login.forgot_password")}</a>
          </div>
          <Input
            bind:value={form.value.password}
            on:input={validate}
            errors={form.errors["password"]}
            type="password"
            id="login-password"
          />
        </div>

        <LoadingButton on:click={handleSubmit} {loading} classes="w-100 text-capitalize">
          {$_("login.login")}
        </LoadingButton>
      </form>
    </div>
  </div>
</EntryScreen>

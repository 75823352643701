<script lang="ts">
  import { onMount } from "svelte";
  import { navigate } from "svelte-navigator";
  import { _ } from "svelte-i18n";
  import { PatientService } from "../../services/patient";
  import { EmailResetForm, Form } from "../../types/Form";
  import EntryScreen from "../lib/EntryScreen.svelte";
  import Loader from "../lib/Loader.svelte";

  export let params: URLSearchParams;

  const svc = new PatientService(navigate);
  const form: Form<EmailResetForm> = new Form({
    token: null,
  });

  let loading = true;
  let isLinkBroken = false;

  const delayedRedirect = () => {
    setTimeout(() => {
      navigate("/dashboard");
    }, 2000);
  };

  onMount(async () => {
    // get email and token from url
    if (params.get("token")) {
      form.value.token = params.get("token");

      // try confirmation
      if (!(await svc.confirmChangeUserEmail(form.value))) {
        isLinkBroken = true;
      } else {
        delayedRedirect();
      }
    } else {
      isLinkBroken = true;
    }

    loading = false;
  });
</script>

<EntryScreen>
  {#if loading}
    <h1>{$_("login.email_reset_proceeding")}</h1>
    <div style="width: 100%; height: 100px; position:relative">
      <Loader />
    </div>
  {:else}
    <div class="text-center px-4">
      {#if isLinkBroken}
        <h2>{$_("login.email_reset_broken_link")}</h2>
        <h5>{$_("login.email_reset_broken_link_suggestion")}</h5>
      {:else}
        <h1>{$_("login.email_reset_ok")}</h1>
        <p>{$_("login.email_reset_ok_sub")}</p>
      {/if}
    </div>
  {/if}
</EntryScreen>

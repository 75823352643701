import { readable } from 'svelte/store';
export const toastSuccessTheme = readable({
    theme: {
        '--toastBackground': '#00b700',
        '--toastColor': 'white',
        '--toastBarBackground': 'lightgreen',
    },
});
export const toastErrorTheme = readable({
    theme: {
        '--toastBackground': 'crimson',
        '--toastColor': 'white',
        '--toastBarBackground': 'red',
    },
});
export const toastWarningTheme = readable({
    theme: {
        '--toastBackground': '#F58025',
        '--toastColor': 'white',
        '--toastBarBackground': 'orange',
    },
});

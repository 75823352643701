<style lang="scss">
  @import "../../styles/_variables";

  .screen-wrapper {
    width: 100vw;
    height: 100vh;
    background-color: #eee;

    :global(.card) {
      box-shadow: none;
      min-width: 400px;
    }
  }
</style>

<div class="screen-wrapper">
  <div class="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
    <div class="logo-wrap mb-4">
      <img src="/assets/images/sifa-logo.svg" alt="sifa-logo" style="max-width: 200px;" />
    </div>

    <slot />
  </div>
</div>

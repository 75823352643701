export class NumPipe {
    constructor(decimals = 2) {
        this.decimals = decimals;
    }
    transform(n) {
        const num = parseFloat(`${n}`);
        if (n || !isNaN(num) || n === 0) {
            return num.toFixed(this.decimals);
        }
        return null;
    }
}

<script lang="ts">
  import { _ } from "svelte-i18n";

  export let id: string = "";
  export let label: string = "";
  export let viewMode = false;
  export let classes: string = "";
  export let value = null;
  export let type: string = "text";
  export let disabled = false;
  export let readonly = false;
  export let isResizable = true;
  export let small = false;
  export let errors: string = null;
  export let placeholder: string = "";
  export let rows: number = null;
  export let showErrorMsg = true;
  export let min: number = null;
  export let max: number = null;
</script>

{#if viewMode}
  <div class="form-view-mode">{value}</div>
{:else if type === "text"}
  <input
    {id}
    bind:value
    on:change
    on:input
    on:blur
    type="text"
    {disabled}
    {readonly}
    aria-invalid={errors ? "true" : undefined}
    aria-errormessage={errors ? `${id}-error` : undefined}
    class="form-control {classes}"
    class:form-control-sm={small}
    {placeholder}
  />
{:else if type === "number"}
  <input
    {id}
    bind:value
    on:change
    on:input
    on:blur
    type="number"
    {min}
    {max}
    {disabled}
    {readonly}
    aria-invalid={errors ? "true" : undefined}
    aria-errormessage={errors ? `${id}-error` : undefined}
    class="form-control {classes}"
    class:form-control-sm={small}
    {placeholder}
  />
{:else if type === "password"}
  <input
    {id}
    bind:value
    on:change
    on:input
    on:blur
    type="password"
    {disabled}
    {readonly}
    aria-invalid={errors ? "true" : undefined}
    aria-errormessage={errors ? `${id}-error` : undefined}
    class="form-control {classes}"
    class:form-control-sm={small}
    {placeholder}
  />
{:else if type === "email"}
  <input
    {id}
    bind:value
    on:change
    on:input
    on:blur
    type="email"
    {disabled}
    {readonly}
    aria-invalid={errors ? "true" : undefined}
    aria-errormessage={errors ? `${id}-error` : undefined}
    class="form-control {classes}"
    class:form-control-sm={small}
    {placeholder}
  />
{:else if type === "textarea"}
  <textarea
    {id}
    bind:value
    on:change
    on:input
    on:blur
    {disabled}
    {readonly}
    rows={rows ? rows : undefined}
    style={!isResizable ? "resize: none" : ""}
    aria-invalid={errors ? "true" : undefined}
    aria-errormessage={errors ? `${id}-error` : undefined}
    class="form-control {classes}"
    class:form-control-sm={small}
    {placeholder}
  />
{:else if type === "checkbox"}
  <input
    {id}
    bind:checked={value}
    on:change
    on:input
    on:blur
    type="checkbox"
    {disabled}
    {readonly}
    aria-invalid={errors ? "true" : undefined}
    aria-errormessage={errors ? `${id}-error` : undefined}
    class="form-check-input {classes}"
    class:form-control-sm={small}
  />
{:else if type === "date"}
  <input
    {id}
    bind:value
    on:change
    on:input
    on:blur
    type="date"
    {disabled}
    {readonly}
    aria-invalid={errors ? "true" : undefined}
    aria-errormessage={errors ? `${id}-error` : undefined}
    class="form-control {classes}"
    class:form-control-sm={small}
  />
{:else if type === "range"}
  <input
    {id}
    bind:value
    on:change
    on:input
    on:blur
    type="range"
    {min}
    {max}
    aria-invalid={errors ? "true" : undefined}
    aria-errormessage={errors ? `${id}-error` : undefined}
    class="form-range {classes}"
    class:form-control-sm={small}
  />
{:else}
  <div>Unsupported input type</div>
{/if}

{#if errors && showErrorMsg}
  <div class="form-error">{$_(errors)}</div>
{/if}

{#if label}
  <label for={id}>{label}</label>
{/if}

import { AuthenticatedAPIService } from "./auth";
import { Diet } from "../types/Diet";
export class PatientService extends AuthenticatedAPIService {
    constructor(nav) {
        super(nav);
    }
    getPatient() {
        return process.env.USE_MOCK
            ? this.getJson(`/assets/mock/user-data.json`)
            : this.getJson(`/api/manage-patients/user-data`);
    }
    getVisits() {
        return process.env.USE_MOCK
            ? this.getJson(`/assets/mock/visits.json`)
            : this.getJson(`/api/manage-patients/visits`);
    }
    async getDiets() {
        return process.env.USE_MOCK
            ? await this.getJson(`/assets/mock/diets.json`)
            : await this.getJson(`/api/manage-patients/diets`);
    }
    async getDiet(id) {
        return process.env.USE_MOCK
            ? new Diet(await this.getJson(`/assets/mock/diet.json`))
            : new Diet(await this.getJson(`/api/manage-patients/diets/${id}`));
    }
    getDietPDF(diet, patientId) {
        const query = {
            pdf_show_info_a: true,
            pdf_show_info_b: true,
            pdf_show_info_c: true,
            pdf_layout: "default",
            patient: patientId,
        };
        diet.downloadFilename = diet.diet_name + '.pdf';
        return this.download(`/api/diets/${diet.id}/pdf`, query, diet);
    }
    async changePassword(form) {
        try {
            const _ = await this.postJson("/api/manage-patients/patient/edit-password", form);
            return true;
        }
        catch (e) {
            return false;
        }
    }
    async changeUserData(form) {
        try {
            const _ = await this.postJson("/api/manage-patients/patient/edit-data", form);
            return true;
        }
        catch (e) {
            return false;
        }
    }
    async confirmChangeUserEmail(form) {
        try {
            const _ = await this.postJson("/api/manage-patients/patient/verify-email-token", form);
            return true;
        }
        catch (e) {
            return false;
        }
    }
}

import { DatePipe } from "../../../../../pipes/date";
function dietName(visit, diets) {
    console.log(visit.diet_id);
    const diet = diets.find((d) => d.id === visit.diet_id);
    return (diet ? diet.diet_name : null) || "";
}
function buildData(visits, diets) {
    return visits
        .map((v) => {
        const results = v.visit_data.results_data.body_composition;
        const formData = v.visit_data.form_data.body_composition;
        return {
            date: v.creation_date,
            diet: { id: v.diet_id, name: dietName(v, diets) },
            data: {
                peso: { value: formData ? parseFloat(formData.misurazioni_base.peso) : null, unit: "kg" },
                massa_grassa: {
                    value: results ? results.valutazione_composizione.massa_grassa.value[1] : null,
                    unit: results ? results.valutazione_composizione.massa_grassa.unit[1] : null,
                },
                massa_magra: {
                    value: results ? results.valutazione_composizione.massa_magra.value[1] : null,
                    unit: results ? results.valutazione_composizione.massa_magra.unit[1] : null,
                },
                metabolismo_basale: {
                    value: results ? results.valutazione_composizione.metabolismo_basale.value : null,
                    unit: results ? results.valutazione_composizione.metabolismo_basale.unit : null,
                },
            },
        };
    })
        .sort((d1, d2) => (d1.date > d2.date ? 1 : d1.date < d2.date ? -1 : 0));
}
export function mainChart(visits, diets, translateFn, withLegend = false) {
    const meta = {
        peso: { color: "#F58025", yAxis: "peso", label: translateFn("visit.peso"), unit: "kg" },
        massa_grassa: { color: "#a68cd5", yAxis: "massa_grassa", label: translateFn("visit.massa_grassa"), unit: "%" },
        massa_magra: { color: "#ff4c83", yAxis: "massa_grassa", label: translateFn("visit.massa_magra"), unit: "%" },
        metabolismo_basale: {
            color: "#4ceddc",
            yAxis: "metabolismo_basale",
            label: translateFn("visit.metabolismo_basale"),
            unit: "kcal",
        },
    };
    const data = buildData(visits, diets);
    return {
        accessibility: {
            enabled: false,
        },
        chart: {
            type: "line",
        },
        credits: {
            enabled: false,
        },
        title: {
            text: null,
        },
        xAxis: {
            // type: 'datetime', non vuole timeseries
            title: { text: null },
            tickPositions: new Array(data.length).fill(0).map((e, i) => i),
            labels: {
                useHTML: true,
                formatter: function () {
                    return `
            <div class="d-flex align-items-center">
              <div style="line-height: 24px;">
                ${DatePipe.transform(new Date(data[this.value].date), "dd/MM/yy")}
              </div>
              <div class="ms-2" style="position: relative;">
                ${data[this.value].diet.id
                        ? '<a href="diets/detail/' +
                            data[this.value].diet.id +
                            '" class="cutlery-ref">' +
                            '<i class="fas fa-utensils"></i>' +
                            "</a>" +
                            '<div class="cutlery-tooltip"><span>' +
                            data[this.value].diet.name +
                            "</span></div>"
                        : ""}
              </div>
            </div>
          `;
                },
            },
        },
        yAxis: ["peso", "massa_grassa", "metabolismo_basale"].map((k) => ({
            id: k,
            labels: {
                format: "{value} " + meta[k].unit,
                style: { color: meta[k].color },
            },
            title: {
                text: meta[k].label + (k === "massa_grassa" ? ` / ${meta["massa_magra"].label}` : ""),
                style: { color: meta[k].color },
            },
            opposite: true,
            min: meta[k].unit === "%" ? 0 : undefined,
            max: meta[k].unit === "%" ? 100 : undefined,
        })),
        series: Object.keys(meta).map((k) => ({
            type: undefined,
            name: meta[k].label,
            color: meta[k].color,
            yAxis: meta[k].yAxis,
            tooltip: { pointFormat: "{point.y:.2f} " + meta[k].unit },
            marker: { radius: 5 },
            data: data.map((d, i) => ({
                name: DatePipe.transform(new Date(d.date), "dd/MM/yy"),
                x: i,
                y: d.data[k].value,
            })),
        })),
        legend: withLegend ? { align: "left" } : { enabled: false },
        responsive: {
            rules: [
                {
                    condition: { maxWidth: 700 },
                    chartOptions: {
                        legend: { enabled: false },
                        yAxis: [
                            { labels: { align: "right", x: 0, y: -6 } },
                            { labels: { align: "left", x: 0, y: -6 } },
                            { visible: false },
                        ],
                    },
                },
            ],
        },
    };
}

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  import Input from "../../../../lib/Input.svelte";
  import { NumPipe } from "../../../../../pipes/num";
  import type { Supplement } from "../../../../../types/Diet";
  import type { Form } from "../../../../../types/Form";

  export let supplements: Supplement[];
  export let form: Form<{ quantities: { [sku: string]: number } }>;
  const dispatch = createEventDispatcher();
  const numTx = new NumPipe();
</script>

<table class="supplements-table">
  <tr>
    <th />
    <!-- <th class="text-center">{$_("diet.price")}</th>
    <th class="text-center">{$_("diet.quantity")}</th> -->
  </tr>

  {#each supplements as supplement, i}
    <tr class="supplement-group">
      <th colspan="3">
        {supplement.supplement_name}
      </th>
    </tr>

<!--
    {#if supplement.alternatives_data}
      {#each supplement.alternatives_data as item}
        <tr>
          <td>{item.name}</td>
          <td class="text-center">{item.status == 'disabled' ? $_("diet.not_available") : "€ " + numTx.transform(item.final_price)}</td>
          <td>
            <div class="d-flex justify-content-center">
              <Input
                on:change={() => dispatch("change")}
                on:input={() => dispatch("change")}
                showErrorMsg={false}
                errors={form.errors.quantities ? form.errors.quantities[item.sku] : null}
                bind:value={form.value.quantities[item.sku]}
                type="number"
                min={0}
                disabled={item.status == 'disabled'}
              />
            </div>
          </td>
        </tr>
      {/each}
    {:else}
      <tr>
        <td colspan="3" class="text-muted">
          {$_("diet.no_supplement_alternatives")}
        </td>
      </tr>
    {/if}
-->
  {/each}
</table>

<style lang="scss">
  @import "../../../../../styles/_variables";

  :global(input[type="number"]) {
    min-width: 100px;
    max-width: 100px;
  }

  .supplements-table {
    width: 100%;
    td,
    th {
      padding: 0.5rem 1rem;
    }
    tr:nth-child(even) {
      background-color: #f4f4f4;
    }
  }

  .supplement-group {
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
    th {
      background-color: azure;
    }
  }
</style>

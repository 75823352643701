<script lang="ts">
  import { _ } from "svelte-i18n";
  import { navigate } from "svelte-navigator";
  // import { ShopService } from "../../../../../services/shop";
  import { Form } from "../../../../../types/Form";
  import { NumPipe } from "../../../../../pipes/num";
  import { Diet, SupplementNote, Supplement, DietRaw } from "../../../../../types/Diet";
  import { PatientService } from "../../../../../services/patient";
  import DashboardCard from "../DashboardCard.svelte";
  import SupplementsTable from "./SupplementsTable.svelte";
  import Nodata from "../../../../lib/Nodata.svelte";
  import Loader from "../../../../lib/Loader.svelte";
  import MissingSupAlertModal from "./MissingSupAlertModal.svelte";

  export let selectedDiet: DietRaw;

  // const shop = new ShopService();
  const svc = new PatientService(navigate);
  let supplements: Supplement[] = [];
  let supplementsNotes: SupplementNote[];
  let form: Form<{ quantities: { [sku: string]: number } }> = new Form({ quantities: {} });
  let loading = true;
  // let totalSpending = 0;
  let missingSupAlertOpen = false;

  const sum = (a: number, b: number) => a + b;
  const qtyValid = (qty: number) => !isNaN(qty) && qty > 0;

  $: init(selectedDiet).then();

  function getShopAlternativesSkus(supplement: Supplement): string[] {
    return supplement.alternatives_data ? supplement.alternatives_data.map((el) => el.sku) : [];
  }

  async function init(_diet: DietRaw) {
    loading = true;

    const diet: Diet = selectedDiet ? await svc.getDiet(selectedDiet.id) : null;
    supplements = diet ? diet.getSupplements() : [];
    supplementsNotes = diet ? diet.getNotes() : [];

    form = new Form({
      quantities: supplements.reduce((prev, supp) => {
        getShopAlternativesSkus(supp).forEach((sku) => (prev[sku] = 0));
        return prev;
      }, {}),
    });

    totalSpendingUpdate();
    loading = false;
  }

  function getQtyErrors(skus: string[]) {
    const qtyErrs = {};
    function qtyInvalid(qty: any) {
      return Number.isNaN(parseInt(`${qty}`)) || qty < 0;
    }
    skus.forEach((sku) => {
      if (qtyInvalid(form.value.quantities[sku])) {
        qtyErrs[sku] = $_("errors.product_qty_gt0");
      }
    });
    return qtyErrs;
  }

  function validate() {
    form.errors = {};
    const skus = Object.keys(form.value.quantities);
    form.valid = skus.some((sku) => form.value.quantities[sku] > 0);
    form.errors["form"] = [];
    if (!form.valid) {
      form.errors["form"].push($_("errors.choose_at_least_one_product"));
    }

    form.errors["quantities"] = getQtyErrors(skus);
    const hasErrors = Object.keys(form.errors["quantities"]).length > 0;
    form.valid = form.valid && !hasErrors;
    if (hasErrors) {
      // since I don't show errors on single fields, show a generic error for all inputs
      form.errors["form"].push($_("errors.products_qty_gt0"));
    }
  }

  function totalSpendingUpdate() {
    // totalSpending = supplements
    //   .map((sup) =>
    //     sup.alternatives_data
    //       .map((alternative) =>
    //         qtyValid(form.value.quantities[alternative.sku])
    //           ? alternative.final_price * form.value.quantities[alternative.sku]
    //           : 0
    //       )
    //       .reduce(sum, 0)
    //   )
    //   .reduce(sum, 0);
  }

  function alternativesQtySum(supplement: Supplement): number {
    return supplement.alternatives_data.map((alt) => form.value.quantities[alt.sku]).reduce(sum);
  }

  function missingSupplements(): boolean {
    return supplements.map((sup) => alternativesQtySum(sup)).some((qty) => qty <= 0);
  }

  function handleShop() {
    form.submitted = true;
    validate();
    if (form.valid) {
      if (missingSupplements()) {
        // open alert modal
        missingSupAlertOpen = true;
      } else {
        goToCart();
      }
    }
  }

  function goToCart() {
    // window.open(shop.cartUrl(form.value.quantities), "_blank").focus();
    // form.submitted = false;
  }
</script>

<DashboardCard title={$_("dashboard.supplements")}>
  {#if loading}
    <Loader />
  {:else}
    {#if supplements && supplements.length}
      <div class="mb-4">
        <h4 class="mb-3">{$_("diet.supplements_area")}</h4>

        <div class="mb-2">
          <small>{$_("diet.supplements_area_subtitle")}</small>
        </div>

        <form class:submitted={form.submitted}>
          <div>
            <div class="table-container mb-3">
              <SupplementsTable on:change={totalSpendingUpdate} {supplements} {form} />
            </div>

            {#if form.errors.form && form.errors.form.length}
              <div class="form-error mb-2">
                {#each form.errors.form as err}
                  <div>{err}</div>
                {/each}
              </div>
            {/if}

            <!-- <div class="d-flex align-items-center justify-content-between">
              <div class="fs-5 fw-bold">
                {$_("dashboard.total")}: € {new NumPipe(2).transform(totalSpending)}
              </div>
              <button type="button" on:click={handleShop} class="btn btn-primary btn-lg text-uppercase">
                {$_("dashboard.buy")}
              </button>
            </div> -->
          </div>
        </form>
      </div>
    {:else}
      <Nodata msgkey="diet.no_available_integrators" />
    {/if}

    {#if supplementsNotes?.length}
      <h4 class="mb-3">{$_("diet.supplements_notes")}</h4>
      <div>
        <div class="table-container notes">
          {#each supplementsNotes as note, i}
            {#each note.parts as notePart}
              {#if notePart.type === "string"}
                {notePart.content}
              {:else if notePart.type === "link"}
                <a href={notePart.content} target="_blank">{notePart.content}</a>
              {/if}
            {/each}
            {#if i < supplementsNotes.length - 1}
              <hr />
            {/if}
          {/each}
        </div>
      </div>
    {/if}
  {/if}
</DashboardCard>

<MissingSupAlertModal bind:open={missingSupAlertOpen} on:confirm={goToCart} />

<style lang="scss">
  .notes {
    white-space: pre-line;
  }
</style>

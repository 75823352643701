<script lang="ts">
  import { _ } from "svelte-i18n";
  import { mainChart } from "./main-chart";
  import Highcharts from "highcharts";
  import DashboardCard from "../DashboardCard.svelte";
  import MarkersList from "./MarkersList.svelte";
  import Loader from "../../../../lib/Loader.svelte";
  import type { Diet } from "../../../../../types/Diet";
  import type { Visit } from "../../../../../types/Visit";

  export let loading: boolean = true;
  export let visits: Visit[] = [];
  export let diets: Diet[] = [];
  let chart: any;

  $: if (!loading) {
    chart = Highcharts.chart("main-chart", mainChart(visits, diets, $_));
  }

  function handleMarkerClick(e: CustomEvent<{ index: number }>): void {
    chart.series[e.detail.index].setVisible(!chart.series[e.detail.index].visible);
  }
</script>

<DashboardCard title={$_("dashboard.visits_chart")}>
  <div class="loader-wrap">
    {#if loading}
      <Loader />
    {/if}

    <!-- need this to exist even on loading, for highcharts to find it -->
    <div id="main-chart" class="mb-3" style="width:100%; height:400px;" />

    {#if !loading}
      <MarkersList on:marker-click={handleMarkerClick} visit={visits[0]} />
    {/if}
  </div>
</DashboardCard>

import { _ } from 'svelte-i18n';
import { toast } from '@zerodevx/svelte-toast';
import { toastErrorTheme } from '../stores/toast';
import { Http } from './_http';
import { StorageService } from './_storage';
/**
 * Base service for interaction with comunica backend.
 *
 * Handles http errors with a default toast behaviour (can be disabled).
 *
 */
export class CMSService extends Http {
    constructor(baseUrl) {
        super(typeof baseUrl !== 'undefined' && baseUrl !== null ? baseUrl : process.env.CMS_URL || '');
        this.storage = new StorageService();
        this.toastErrOpts = new Promise(resolve => toastErrorTheme.subscribe(v => resolve(v)));
    }
    get phpsessid() {
        return this.storage.get('PHPSESSID');
    }
    setAuthHeaders(options, isProtected) {
        const newOptions = Object.assign({}, options);
        if (isProtected) {
            newOptions.headers = Object.assign({ PHPSESSID: this.phpsessid }, (options.headers ? options.headers : {}));
        }
        return newOptions;
    }
    getResponse(resp) {
        return Object.keys(resp).includes('response') ? resp.response : null;
    }
    async getJson(resource, queryParams = {}, options = {}, handleErr = true, isProtected = true) {
        return handleErr
            ? this.getResponse(await this.handleError(resource, () => super.getJson(resource, queryParams, this.setAuthHeaders(options, isProtected))))
            : this.getResponse(await super.getJson(resource, queryParams, this.setAuthHeaders(options, isProtected)));
    }
    async postJson(resource, body = {}, options = {}, handleErr = true, isProtected = true) {
        return handleErr
            ? this.getResponse(await this.handleError(resource, () => super.postJson(resource, body, this.setAuthHeaders(options, isProtected))))
            : this.getResponse(await super.postJson(resource, body, this.setAuthHeaders(options, isProtected)));
    }
    async download(resource, queryParams = {}, body = {}, options = {}, handleErr = true, isProtected = true) {
        return handleErr
            ? await this.handleError(resource, () => super.downloadPost(resource, queryParams, body, this.setAuthHeaders(options, isProtected)))
            : await super.downloadPost(resource, queryParams, body, this.setAuthHeaders(options, isProtected));
    }
    // async put() { }
    // async delete() { }
    // return translator function in a synch way
    getTx() {
        return new Promise((resolve) => {
            _.subscribe(tx => resolve(tx));
        });
    }
    /**
     * Standard error handling for API calls. Just push an error toast on fail.
     * Rethrows errors such that caller knows if the request has failed.
     */
    async handleError(resource, cb) {
        try {
            return await cb();
        }
        catch (err) {
            const body = err.response;
            const tx = await this.getTx();
            const msg = (body === null || body === void 0 ? void 0 : body.error) ? tx(`server_errors.${body.error}`) : (body === null || body === void 0 ? void 0 : body.errors) ? body.errors.map((e) => tx(e)).toString() : err.statusText;
            this.showError(`${process.env.DEBUG ? resource + ' ' : ''}${msg}`);
            throw err;
        }
    }
    async showError(msg) {
        toast.push(msg, await this.toastErrOpts);
        console.error(msg);
    }
}

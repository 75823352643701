<script lang="ts">
  import Loader from "./Loader.svelte";

  export let big = false;
  export let loading: boolean;
  export let classes: string = "";
</script>

<button
  on:click
  type="button"
  class="btn btn-primary position-relative {classes}"
  class:btn-lg={big}
  disabled={loading}
>
  <div class:transparent={loading}>
    <slot />
  </div>
  {#if loading}
    <Loader small color="white" />
  {/if}
</button>

<style lang="scss">
  .transparent {
    opacity: 0.5;
  }
</style>

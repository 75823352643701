const PWD_MIN_LEN = 8;
const PWD_MIN_N_NUM = 1;
const PWD_MIN_N_UPPERCASE = 1;
function minLenErr(translator) {
    return translator('forms.pwd_min_len') + ` ${PWD_MIN_LEN} ` + translator('forms.chars');
}
function minNNumErr(translator) {
    return translator('forms.pwd_not_contains') + ` ${PWD_MIN_N_NUM} ` + translator(PWD_MIN_N_NUM > 1 ? 'forms.numbers' : 'forms.number');
}
function minNUppercase(translator) {
    return translator('forms.pwd_not_contains') + ` ${PWD_MIN_N_UPPERCASE} ` + translator(PWD_MIN_N_NUM > 1 ? 'forms.uppercases' : 'forms.uppercase');
}
function hasNumber(str) {
    const pattern = new Array(PWD_MIN_N_NUM).fill("\\d").join(".*"); // \d.*\d ...
    const regex = new RegExp(pattern, 'g');
    return regex.test(str);
}
function hasUppercase(str) {
    const pattern = new Array(PWD_MIN_N_NUM).fill("[A-Z]").join(".*"); // [A-Z].*[A-Z] ...
    const regex = new RegExp(pattern, 'g');
    return regex.test(str);
}
export function validatePassword(pwd, translator) {
    const errors = [];
    if (!pwd || pwd.length < PWD_MIN_LEN)
        errors.push(minLenErr(translator));
    if (!pwd || !hasNumber(pwd))
        errors.push(minNNumErr(translator));
    if (!pwd || !hasUppercase(pwd))
        errors.push(minNUppercase(translator));
    return errors;
}

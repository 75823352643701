import { NumPipe } from "../../../../../pipes/num";
export function dietChart(diet, translateFn) {
    const meta = {
        carbohydrates: { color: "#fcd4dc", label: translateFn("diet.carbohydrates"), unit: "g" },
        total_fiber: { color: "#f6adbb", label: translateFn("diet.total_fiber"), unit: "g" },
        proteins: { color: "#c8e6fa", label: translateFn("diet.proteins"), unit: "g" },
        lipids: { color: "#faeed2", label: translateFn("diet.lipids"), unit: "g" },
        alcohol: { color: "#ffdbb1", label: translateFn("diet.alcohol"), unit: "g" },
    };
    return {
        accessibility: {
            enabled: false,
        },
        chart: {
            type: "pie",
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: false,
                },
            },
        },
        credits: {
            enabled: false,
        },
        title: {
            text: null,
        },
        series: diet
            ? [
                {
                    name: "Nutrients",
                    colorByPoint: true,
                    data: Object.keys(meta).map((k) => {
                        return {
                            name: meta[k].label,
                            y: Math.round(diet[k]),
                            color: meta[k].color,
                        };
                    }),
                },
            ]
            : [],
    };
}
function CALC_PERCENTAGE_MACRO(tot, part) {
    return new NumPipe(1).transform((part / tot) * 100);
}
// Associazione tra il campo nella schedule e il relativo campo nel supplemento
const SUPPLEMENT_NUTRIENTS = {
    proteins: "totalProteins",
    carbohydrates: "totalCarbohydrates",
    lipids: "totalLipids",
    total_fiber: "totalTotal_fiber",
    alcohol: "totalAlcohol",
    energy_kcal: "totalEnergy_kcal",
};
export function getNutrientMergedValue(diet, nutrient) {
    if (!diet) {
        return 0;
    }
    let value = diet[nutrient];
    for (const schedule of diet.schedules || []) {
        // handle old diets with no supplements
        if (schedule.supplements) {
            value += schedule.supplements[SUPPLEMENT_NUTRIENTS[nutrient]];
        }
    }
    return value;
}
export function kcalPercentages(diet) {
    const totalCarbohydratesKcal = getNutrientMergedValue(diet, "carbohydrates") * 4;
    const totalProteinsKcal = getNutrientMergedValue(diet, "proteins") * 4;
    const totalLipidsKcal = getNutrientMergedValue(diet, "lipids") * 9;
    const totalFibersKcal = getNutrientMergedValue(diet, "total_fiber") * 2;
    const totalAlcoholKcal = getNutrientMergedValue(diet, "alcohol") * 7;
    const totalKcal = getNutrientMergedValue(diet, "energy_kcal");
    return {
        carbohydrates: CALC_PERCENTAGE_MACRO(totalKcal, totalCarbohydratesKcal),
        total_fiber: CALC_PERCENTAGE_MACRO(totalKcal, totalFibersKcal),
        proteins: CALC_PERCENTAGE_MACRO(totalKcal, totalProteinsKcal),
        lipids: CALC_PERCENTAGE_MACRO(totalKcal, totalLipidsKcal),
        alcohol: CALC_PERCENTAGE_MACRO(totalKcal, totalAlcoholKcal),
    };
}
export const nutriColors = {
    proteins: "protein",
    carbohydrates: "carbohydrates",
    lipids: "lipids",
    total_fiber: "fibers",
    alcohol: "alcohol",
};

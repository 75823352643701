/**
 * Checks every field in the object has a proper value
 */
export function checkRequiredFields(form) {
    return Object.keys(form).reduce((v, k) => !!form[k] && v, true);
}
/**
 * Builds an `errors` object with requiring each null field in the given object
 */
export function requireFields(form) {
    return Object.keys(form).reduce((v, k) => {
        v[k] = !form[k] ? "forms.required_field" : undefined;
        return v;
    }, {});
}

<script lang="ts">
  import { init /* getLocaleFromNavigator */ } from "svelte-i18n";
  import { Router, Route } from "svelte-navigator";
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import { AuthenticationService } from "./services/auth";
  import ProtectedRoute from "./components/lib/ProtectedRoute.svelte";
  import Login from "./components/routes/Login.svelte";
  import PasswordReset from "./components/routes/PasswordReset.svelte";
  import ForgotPassword from "./components/routes/ForgotPassword.svelte";
  import Redirect from "./components/routes/Redirect.svelte";
  import Dashboard from "./components/routes/protected/dashboard/Dashboard.svelte";
  import EmailReset from "./components/routes/EmailReset.svelte";

  init({
    fallbackLocale: "it",
    initialLocale: "it", // getLocaleFromNavigator,
  });

  const toastOptions = {};
  const authSvc: AuthenticationService = new AuthenticationService();
  authSvc.init();
</script>

<Router>
  <Route path="/*">
    <Redirect />
  </Route>
  <Route path="/login">
    <Login />
  </Route>
  <Route path="/forgot-password">
    <ForgotPassword />
  </Route>
  <Route path="/password-reset" let:location>
    <PasswordReset params={new URLSearchParams(location.search)} />
  </Route>
  <Route path="/email-reset" let:location>
    <EmailReset params={new URLSearchParams(location.search)} />
  </Route>
  <ProtectedRoute path="/dashboard">
    <Dashboard />
  </ProtectedRoute>
</Router>

<SvelteToast options={toastOptions} />

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { navigate } from "svelte-navigator";
  import { toast } from "@zerodevx/svelte-toast";
  import { _ } from "svelte-i18n";
  import { PatientService } from "../../../../../services/patient";
  import { ChangeUserDataForm, Form } from "../../../../../types/Form";
  import { debounce } from "../../../../../utils/debounce";
  import { checkRequiredFields, requireFields } from "../../../../../utils/form-utils";
  import { validateEmail } from "../../../../../utils/validation-email";
  import { toastSuccessTheme, toastWarningTheme } from "../../../../../stores/toast";
  import Input from "../../../../lib/Input.svelte";
  import LoadingButton from "../../../../lib/LoadingButton.svelte";
  import type { Patient } from "../../../../../types/Patient";

  export let id: string;
  export let patient: Patient;

  const dispatch = createEventDispatcher();
  const svc = new PatientService(navigate);
  let loading = false;
  $: emailChanged = form.value.email !== patient.email;

  const form = new Form<ChangeUserDataForm>({
    firstname: patient.firstname,
    lastname: patient.lastname,
    birthdate: patient.birthdate,
    email: patient.email,
  });

  function closeModal() {
    // TODO usare bootstrapjs
    document.getElementById("userDataModalCloseBtn").click();
  }

  async function handleSubmit() {
    loading = true;
    validate();
    form.submitted = true;
    if (form.valid) {
      const success = await svc.changeUserData(form.value);
      if (success) {
        closeModal();
        toast.push($_("dashboard.user_data_changed"), $toastSuccessTheme);
        if (emailChanged) {
          toast.push($_("dashboard.user_email_changed"), $toastWarningTheme);
        }
        dispatch("userdata-change");
      }
    }
    loading = false;
  }

  const debouncedValidation: () => void = debounce(250, validate);

  function validate() {
    const emailErrors = validateEmail(form.value.email, $_);
    form.valid = checkRequiredFields(form.value);
    form.errors = requireFields(form.value);
    form.errors.email = form.errors.email ? form.errors.email : emailErrors.join("\n");
  }
</script>

<div class="modal fade" {id} tabindex="-1" aria-labelledby="userDataModalCloseBtn" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="d-flex justify-content-between mb-3">
          <h4 class="modal-title">{$_("dashboard.change_user_data")}</h4>
          <button
            type="button"
            id="userDataModalCloseBtn"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <form class:submitted={form.submitted}>
          <div class="mb-3">
            <label for="udchange-firstname">{$_("dashboard.firstname")}</label>
            <Input
              bind:value={form.value.firstname}
              on:input={debouncedValidation}
              errors={form.errors["firstname"]}
              type="text"
              id="udchange-firstname"
            />
          </div>

          <div class="mb-3">
            <label for="udchange-lastname">{$_("dashboard.lastname")}</label>
            <Input
              bind:value={form.value.lastname}
              on:input={debouncedValidation}
              errors={form.errors["lastname"]}
              type="text"
              id="udchange-lastname"
            />
          </div>

          <div class="mb-3">
            <label for="udchange-birthdate">{$_("dashboard.birthdate")}</label>
            <Input
              bind:value={form.value.birthdate}
              on:input={debouncedValidation}
              errors={form.errors["birthdate"]}
              type="date"
              id="udchange-birthdate"
            />
          </div>

          <div class="mb-3">
            <label for="udchange-email">{$_("dashboard.email")}</label>
            <Input
              bind:value={form.value.email}
              on:input={debouncedValidation}
              errors={form.errors["email"]}
              type="email"
              id="udchange-email"
            />
          </div>

          {#if emailChanged}
            <div class="alert email-change-alert p-1 small">
              {$_("dashboard.email_change_notes")}
            </div>
          {/if}

          <div class="text-end">
            <LoadingButton on:click={handleSubmit} {loading} big>
              {$_("login.confirm")}
            </LoadingButton>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .email-change-alert {
    background-color: rgba(var(--bs-warning-rgb), 0.3) !important;
    border: 1px solid var(--bs-warning-rgb);
  }
</style>

<script lang="ts">
  export let small = false;
  export let color = "primary";
  export let isPatientCard: boolean = false;
  export let inline = false;
</script>

{#if isPatientCard}
  <div class="h-100 patient-loader">
    <div class="h-100 d-flex flex-column justify-content-between">
      <div>
        <div class="header mb-4">
          <div class="loading-txt w-01 fs-2 mb-2">&nbsp;</div>
          <div class="loading-txt w-02">&nbsp;</div>
        </div>
        <div class="age mb-4">
          <div class="loading-txt  w-03 mb-2">&nbsp;</div>
          <div class="loading-txt  w-03">&nbsp;</div>
        </div>
      </div>

      <div class="doctor-selection">
        <div class="loading-txt  w-02">&nbsp;</div>
      </div>
    </div>
  </div>
{:else}
  <div class="loader {inline ? 'inline' : 'abs-pos'}">
    <div class="spinner-border text-{color}" class:spinner-border-sm={small} role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
{/if}

<style lang="scss">
  @import "../../styles/_variables";

  .loader {
    &.abs-pos {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .patient-loader .loading-txt {
    background: linear-gradient(-45deg, $primary, #80dfff, $primary);
    background-size: 200% 200%;
    animation: gradient 1s ease infinite;
    border-radius: 0.25rem;

    &.w-01 {
      width: 50%;
    }
    &.w-02 {
      width: 70%;
    }
    &.w-03 {
      width: 60%;
    }
  }

  @keyframes gradient {
    0% {
      background-position: 200% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
</style>

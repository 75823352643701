function notAnEmail(translator) {
    return translator('forms.email_not_valid');
}
function isMail(str) {
    const pattern = "^\\S+@\\S+\\.\\S+$";
    const regex = new RegExp(pattern, 'g');
    return regex.test(str);
}
export function validateEmail(mail, translator) {
    const errors = [];
    if (!mail || !isMail(mail))
        errors.push(notAnEmail(translator));
    return errors;
}

<script lang="ts">
  import { _ } from "svelte-i18n";
  import { navigate } from "svelte-navigator";
  import { PatientService } from "../../../../../services/patient";
  import { dietChart, kcalPercentages, nutriColors, getNutrientMergedValue } from "./diet-chart";
  import Highcharts from "highcharts";
  import DashboardCard from "../DashboardCard.svelte";
  import Loader from "../../../../lib/Loader.svelte";
  import Nutrient from "./Nutrient.svelte";
  import Nodata from "../../../../lib/Nodata.svelte";
  import type { Diet } from "../../../../../types/Diet";
  import { userStore } from "../../../../../stores/user";
  import { NumPipe } from "../../../../../pipes/num";
  import { onMount } from "svelte";

  export let loading: boolean;
  export let diets: Diet[] = [];
  export let selectedDiet: Diet = null;
  // $: selectedDiet = diets.find((d) => d.id === selectedDietId);

  const svc = new PatientService(navigate);
  const nutrients = ["proteins", "carbohydrates", "lipids", "total_fiber", "alcohol"];

  let chart: Highcharts.Chart;
  let percentages = null;
  let dietDownloadLoading = false;

  $: if (!loading) update(selectedDiet);

  onMount(update);

  function update(diet: Diet = null) {
    if (!selectedDiet) {
      selectedDiet = diet ? diet : diets.length ? diets[0] : null;
    }
    if (selectedDiet) {
      chart = Highcharts.chart("diet-chart", dietChart(selectedDiet, $_));
      percentages = kcalPercentages(selectedDiet);
    }
  }

  function handleSelect(event: any) {
    update(event.target.value);
  }

  async function handlePdfDownload() {
    dietDownloadLoading = true;
    await svc.getDietPDF(selectedDiet, $userStore.id);
    dietDownloadLoading = false;
  }
</script>

<DashboardCard title={$_("dashboard.diets")}>
  <div class="loader-wrap">
    {#if loading}
      <Loader />
    {:else if diets && diets.length}
      <div class="mb-3">
        <div class="d-flex">
          <div class="flex-grow-1 me-3">
            <select
              class="form-select"
              name="diet-select"
              id="diet-select"
              bind:value={selectedDiet}
              on:change={handleSelect}
            >
              {#each diets as diet}
                <option value={diet} selected={!!selectedDiet && diet.id === selectedDiet.id}>
                  {diet.diet_name}
                </option>
              {/each}
            </select>
          </div>

          <button
            on:click={handlePdfDownload}
            class="as-link btn btn-primary text-white btn-md mr-2 px-2 py-0 d-flex justify-content-center align-items-center"
            style="min-width: 50px;"
          >
            {#if dietDownloadLoading}
              <Loader inline small color="white" />
            {:else}
              <i class="sifa-dieta-icon-diets-DOWNLOAD" style="font-size: 24px;" />
            {/if}
          </button>
        </div>
      </div>
    {:else}
      <Nodata msgkey="diet.no_available_diets" />
    {/if}

    {#if selectedDiet}
      <div class="row" style="margin: 0 -0.25rem !important;">
        {#each nutrients as nutrient}
          <div class="col-6 col-md-3 col-xl-6 mb-2 px-1">
            <Nutrient datum={{ label: nutrient, value: getNutrientMergedValue(selectedDiet, nutrient), unit: "g" }} />
          </div>
        {/each}
      </div>
    {/if}

    <div class="row">
      <!-- need this to exist even on loading, for highcharts to find it -->
      <div class="col-6">
        <div id="diet-chart" class="me-4" style="min-height:200px;" />
      </div>
      <div class="col-6">
        {#if selectedDiet}
          <div class="custom-legend h-100 d-flex flex-column justify-content-center">
            <div class="title">
              {$_("diet.energy_kcal")}: {new NumPipe(0).transform(getNutrientMergedValue(selectedDiet, "energy_kcal"))} kcal
            </div>
            {#each nutrients as nutrient}
              <div class="field">
                {$_(`diet.${nutrient}`)}:
                <span class="percentage badge bg-{nutriColors[nutrient]}">{percentages[nutrient]} %</span>
              </div>
            {/each}
          </div>
        {/if}
      </div>
    </div>
  </div>
</DashboardCard>

<style lang="scss">
  @import "../../../../../styles/_variables";

  .custom-legend {
    .title {
      font-weight: bold;
    }
    .field {
      font-size: smaller;
    }
  }
  .percentage {
    color: black;
    &.bg-carbohydrates {
      background-color: $carbohydrates !important;
    }
    &.bg-fibers {
      background-color: $fibers !important;
    }
    &.bg-protein {
      background-color: $protein !important;
    }
    &.bg-lipids {
      background-color: $lipids !important;
    }
    &.bg-alcohol {
      background-color: $alcohol !important;
    }
  }
</style>

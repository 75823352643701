<script lang="ts">
  import { _ } from "svelte-i18n";
  import { NumPipe } from "../../../../../pipes/num";
  import { nutriColors } from "./diet-chart";

  export let datum: { label: string; value: number; unit: string };
</script>

<div class="nutrient-info {nutriColors[datum.label]}-border-color active">
  <p class="description">{$_(`diet.${datum.label}`)}</p>
  <p>
    <span class="value">{datum.value || datum.value === 0 ? new NumPipe().transform(datum.value, 1) : "--"}</span>
    <span class="unit">{datum.value || datum.value === 0 ? datum.unit : ""}</span>
  </p>
</div>

<style lang="scss">
  @import "../../../../../styles/_variables";

  $inactive-color: #bbb;
  .nutrient-info {
    cursor: pointer;
    background-color: #f5f5f5;
    padding: 6px;
    height: 100%;
    border-top: 4px solid;
    border-top-color: $inactive-color !important;
    &:not(.active) {
      color: $inactive-color;
    }

    &.active {
      &.carbohydrates-border-color {
        border-color: $carbohydrates !important;
      }
      &.fibers-border-color {
        border-color: $fibers !important;
      }
      &.protein-border-color {
        border-color: $protein !important;
      }
      &.lipids-border-color {
        border-color: $lipids !important;
      }
      &.alcohol-border-color {
        border-color: $alcohol !important;
      }
    }

    p {
      margin-bottom: 0px;
      &.description {
        text-transform: uppercase;
        font-size: 11px;
        line-height: 12px;
        margin-bottom: 0px;
        font-weight: 700;
        min-height: 18px;
        max-height: 18px;
      }
      span.value {
        font-weight: bold;
        font-size: 16px;
        margin-right: 5px;
      }
    }
  }
</style>

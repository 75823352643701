<script lang="ts">
  import { onMount } from "svelte";
  import { Route } from "svelte-navigator";
  import { AuthenticationService } from "../../services/auth";
  import { userStore } from "../../stores/user";

  export let path: string;
  export let component: Function = null;

  // update protected route status on store changes, also works as initializer
  let isAuth: boolean = false;
  $: isAuth = $userStore ? !!$userStore.id : false;

  onMount(async () => (isAuth = await AuthenticationService.isAuthenticated()));
</script>

{#if isAuth}
  <Route {path} {component}>
    <slot />
  </Route>
{/if}

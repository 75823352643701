export class DatePipe {
    static transform(d, format) {
        if (!DatePipe.valid(d)) {
            return null;
        }
        switch (format) {
            case "dd/MM/yy":
                const fs = [{ day: "2-digit" }, { month: "2-digit" }, { year: "2-digit" }];
                return DatePipe.join(d, fs, "/");
            default:
                return d.toISOString().slice(0, 11);
        }
    }
    static valid(date) {
        return date instanceof Date && !isNaN(date);
    }
    static join(t, a, s) {
        function format(m) {
            let f = new Intl.DateTimeFormat("en", m);
            return f.format(t);
        }
        return a.map(format).join(s);
    }
}

<script lang="ts">
  import { onMount } from "svelte";
  import { navigate } from "svelte-navigator";
  import { AuthenticationService } from "../../services/auth";

  onMount(async () => {
    if (await AuthenticationService.isAuthenticated()) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  });
</script>

<script lang="ts">
  import { _ } from "svelte-i18n";
  import { AuthenticationService } from "../../services/auth";
  import { debounce } from "../../utils/debounce";
  import { checkRequiredFields, requireFields } from "../../utils/form-utils";
  import EntryScreen from "../lib/EntryScreen.svelte";
  import LoadingButton from "../lib/LoadingButton.svelte";
  import Input from "../lib/Input.svelte";
  import type { ForgotPasswordForm, Form } from "../../types/Form";

  const authSvc = new AuthenticationService();
  const form: Form<ForgotPasswordForm> = {
    value: {
      email: null,
    },
    submitted: false,
    valid: false,
    errors: {},
  };

  let loading = false;
  let done = false;

  async function handleSubmit() {
    loading = true;
    validate();
    form.submitted = true;
    if (form.valid) {
      const success = await authSvc.requestPasswordReset(form.value);
      if (success) {
        done = true;
      }
    }
    loading = false;
  }

  const validate: () => void = debounce(250, () => {
    form.valid = checkRequiredFields(form.value);
    form.errors = requireFields(form.value);
  });
</script>

<EntryScreen>
  {#if done}
    <div class="text-center px-4">
      <h1>{$_("login.pw_reset_request_done")}</h1>
      <p>{$_("login.pw_reset_request_done_suggestion")}</p>
    </div>
  {:else}
    <div class="card">
      <div class="card-body p-4">
        <h1>{$_("login.request_password_reset")}</h1>
        <form class:submitted={form.submitted}>
          <div class="mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <label for="reqPwReset-email">{$_("login.username")}</label>
              <a class="small" href="/login">{$_("login.back_to_login")}</a>
            </div>
            <Input
              bind:value={form.value.email}
              on:input={validate}
              errors={form.errors["email"]}
              type="email"
              id="reqPwReset-email"
            />
          </div>

          <LoadingButton on:click={handleSubmit} {loading} classes="w-100 text-capitalize">
            {$_("login.send")}
          </LoadingButton>
        </form>
      </div>
    </div>
  {/if}
</EntryScreen>

<script lang="ts">
  import { navigate } from "svelte-navigator";
  import { toast } from "@zerodevx/svelte-toast";
  import { _ } from "svelte-i18n";
  import { PatientService } from "../../../../../services/patient";
  import { toastSuccessTheme } from "../../../../../stores/toast";
  import { ChangePasswordForm, Form } from "../../../../../types/Form";
  import { debounce } from "../../../../../utils/debounce";
  import { checkRequiredFields, requireFields } from "../../../../../utils/form-utils";
  import { validatePassword } from "../../../../../utils/validation-password";
  import Input from "../../../../lib/Input.svelte";
  import LoadingButton from "../../../../lib/LoadingButton.svelte";

  export let id: string;

  const svc = new PatientService(navigate);
  let loading = false;
  let pwdErrors = [];

  const form = new Form<ChangePasswordForm>({
    password: "",
    // newPassword: "",
    passwordConfirm: "",
  });

  function closeModal() {
    // TODO usare bootstrapjs
    document.getElementById("pwdModalCloseBtn").click();
  }

  async function handleSubmit() {
    loading = true;
    validate();
    form.submitted = true;
    if (form.valid) {
      const success = await svc.changePassword(form.value);
      if (success) {
        closeModal();
        toast.push($_("dashboard.user_data_changed"), $toastSuccessTheme);
      }
    }
    loading = false;
  }

  const debouncedValidation: () => void = debounce(250, validate);

  function validate() {
    pwdErrors = validatePassword(form.value.password, $_);
    form.valid =
      checkRequiredFields(form.value) && !pwdErrors.length && form.value.password === form.value.passwordConfirm;
    form.errors = requireFields(form.value);
    form.errors["passwordMatch"] = form.value.password !== form.value.passwordConfirm;
  }
</script>

<div class="modal fade" {id} tabindex="-1" aria-labelledby="pwdChangeModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="d-flex justify-content-between mb-3">
          <h4 class="modal-title" id="pwdChangeModalLabel">{$_("dashboard.change_password")}</h4>
          <button type="button" id="pwdModalCloseBtn" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <form class:submitted={form.submitted}>
          <div class="mb-3">
            <label for="pwdchange-password">{$_("login.password")}</label>
            <Input
              bind:value={form.value.password}
              on:input={debouncedValidation}
              errors={form.errors["password"]}
              type="password"
              id="pwdchange-password"
            />
          </div>

          <!-- <div class="mb-3">
            <label for="pwdchange-new-password">{$_("login.new_password")}</label>
            <Input
              bind:value={form.value.newPassword}
              on:input={debouncedValidation}
              errors={form.errors["newPassword"]}
              type="password"
              id="pwdchange-new-password"
            />
          </div> -->

          <div class="mb-3">
            <label for="pwdchange-password-confirm">{$_("login.confirm_password")}</label>
            <Input
              bind:value={form.value.passwordConfirm}
              on:input={debouncedValidation}
              errors={form.errors["passwordConfirm"]}
              type="password"
              id="pwdchange-password-confirm"
            />
          </div>

          {#if pwdErrors && pwdErrors.length > 0}
            <div class="mb-3">
              {#each pwdErrors as err}
                <div class="form-error">
                  {err}
                </div>
              {/each}
            </div>
          {/if}

          {#if form.errors["passwordMatch"]}
            <div class="form-error">{$_(`forms.password_match`)}</div>
          {/if}

          <div class="text-end">
            <LoadingButton on:click={handleSubmit} {loading} big>
              {$_("login.confirm")}
            </LoadingButton>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<script lang="ts">
  import { _ } from "svelte-i18n";
  import { navigate } from "svelte-navigator";
  import { AuthenticationService } from "../../../../../services/auth";
  import ChangePasswordModal from "./ChangePasswordModal.svelte";
  import ChangeUserDataModal from "./ChangeUserDataModal.svelte";
  import DashboardCard from "../DashboardCard.svelte";
  import Loader from "../../../../lib/Loader.svelte";
  import IconBtn from "../../../../lib/IconBtn.svelte";
  import DoctorChooser from "./DoctorChooser.svelte";
  import type { Doctor } from "../../../../../types/Doctor";
  import type { Patient } from "../../../../../types/Patient";
  import { PatientService } from "../../../../../services/patient";

  export let loading: boolean;
  export let patient: Patient;
  export let doctors: Doctor[];
  export let selectedDoctor: Doctor = null;

  const svc = new PatientService(navigate);

  async function handleLogout() {
    await AuthenticationService.getManagedLogout(navigate)();
  }

  async function handleUserDataChange() {
    loading = true;
    patient = await svc.getPatient();
    loading = false;
  }
</script>

<DashboardCard colorClass="primary">
  {#if loading}
    <Loader isPatientCard />
  {:else}
    <div class="h-100 d-flex flex-column justify-content-between">
      <div class="flex-grow-1">
        <div class="header d-flex justify-content-between mb-4">
          <div>
            <h2 class="text-capitalize">
              {patient.firstname}
              {patient.lastname}
            </h2>
            <span>{patient.email}</span>
          </div>

          <div class="dropdown">
            <div id="user-menu-btn" data-bs-toggle="dropdown" aria-expanded="false">
              <IconBtn icon="fas fa-ellipsis-vertical" iconColor="white" size={5} />
            </div>

            <ul class="dropdown-menu" aria-labelledby="user-menu-btn">
              <li>
                <a class="dropdown-item" href={null} on:click={handleLogout}>
                  Logout
                  <i class="ms-2 fa fa-arrow-right-from-bracket" />
                </a>
              </li>
              <li>
                <a class="dropdown-item" href={null} data-bs-toggle="modal" data-bs-target="#pwdChangeModal">
                  {$_("dashboard.change_password")}
                </a>
              </li>
              <li>
                <a class="dropdown-item" href={null} data-bs-toggle="modal" data-bs-target="#userDataChangeModal">
                  {$_("dashboard.change_user_data")}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="age mb-4">
          Nato il: {new Date(patient.birthdate).toLocaleDateString()}
        </div>
      </div>

      <DoctorChooser on:doctor-change {doctors} bind:selected={selectedDoctor} />
    </div>
  {/if}
</DashboardCard>

<ChangePasswordModal id="pwdChangeModal" />

{#if patient}
  <ChangeUserDataModal on:userdata-change={handleUserDataChange} id="userDataChangeModal" {patient} />
{/if}
